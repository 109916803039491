import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['dropdown']

  touch (event) {
    let toRemove = this.dropdownTargets.filter(dropdown => {
        return !dropdown.contains(event.target) && dropdown != event.target
    })
    
    toRemove.forEach(dropdown => dropdown.classList.remove('is-active'))
  }
}
