import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['error', 'success', 'fields', 'email']

  submitted () {
    this._clearOutcomes()
  }

  _clearOutcomes () {
    let outcomes = [this.errorTarget, this.successTarget]
    outcomes.forEach(target => target.classList.add('is-hidden'))
  }

  _clearValidationErrors () {
    this.element.querySelectorAll('.help.is-danger').forEach(error => error.innerHTML = '')
    this.element.querySelectorAll('input.is-danger').forEach(input => input.classList.remove('is-danger'))
  }

  created (event) {
    let [data, status, xhr] = event.detail
    this.emailTarget.innerHTML = data.email
    this.fieldsTarget.classList.add('is-hidden')
    this.successTarget.classList.remove('is-hidden')
    this.element.reset()
  }

  failed (event) {
    let [data, status, xhr] = event.detail
    
    this._clearValidationErrors()

    if (this._isValidationError(xhr.status)) {
      let errors = data.errors
      for (let attr in errors) {
        if (errors.hasOwnProperty(attr)) {
          document.querySelector(`#teacher_training_interest_${attr}`).classList.add('is-danger')
          document.querySelector(`#${attr}_validation_error`).innerHTML = errors[attr]
        } 
      }
    } else { 
      this.errorTarget.classList.remove('is-hidden')
    }
  }

  _isValidationError(status) {
    return status == 400
  }
}
