import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['burger', 'menu']
  
  toggle () {
    let toggleables = [this.burgerTarget, this.menuTarget]
    toggleables.forEach(target => target.classList.toggle('is-active'))
  }
}
